/** @jsx jsx */
import { FC } from 'react'
import { Box, jsx, Text, Container, Divider } from 'theme-ui'

import Halo from '../components/halo'
// import Intro from '../components/intro'
// import Layout from '../components/layout'
// import Navigation from '../components/navig'
// import Block from '../components/block'
// import IconMine from '../components/icons/mine'
// import IconLiquid from '../components/icons/liquid'
// import IconMarket from '../components/icons/market'
// import IconMulti from '../components/icons/multi'
import PageFrame from '../components/page'
// import Plate from '../components/plate'
// import Faq from '../components/faq'
// import PortableText from '../components/portableText'

export interface SpeakingProps {
  data: any
}

const RagePage: FC<SpeakingProps> = ({ data }) => {
  return (
    <PageFrame variant="box.plateTint3" invert={false} alt={false} adjustHeight={'3rem'}>
      <Halo title="FAQ" url="https://nft.rage.fan/faq" />
      <Box className="filler2" sx={{ bg: 'base', mt: 0 }}>
        <Box sx={{ py: 2, maxWidth: '60ch', mx: 'auto', pt: 7, pb: 3 }}>
          <Container mb={5} sx={{ px: [5, 0, 0] }}>
            <Text sx={{ color: 'primarytext', fontSize: 6, fontWeight: 600, mb: 2 }}>Frequently Asked Questions</Text>
            <Text variant="sectionBodyDim" sx={{ lineHeight: 'body', fontSize: 3, pt: 0, mt: 0 }}></Text>
          </Container>
          <Divider color="grays.3" />
        </Box>
        <Box sx={{ py: 2, maxWidth: '60ch', mx: 'auto', pt: 4, pb: 3 }}>
          <Container mb={5} sx={{ px: [5, 0, 0] }}>
            {/* <Flex variant="box.plateBase">
              {data.faq?.edges &&
                data.faq?.edges.map((_fq: any) => (
                  <Faq title={_fq.node.title} description="x" />
                ))}
            </Flex> */}
          </Container>
        </Box>
      </Box>
    </PageFrame>
  )
}

export default RagePage

// export const pageQuery = graphql`
//   query FaqPageQuery {
//     faq: allSanityFaq {
//       edges {
//         node {
//           _id
//           title
//           _rawBody
//         }
//       }
//     }
//   }
// `
